export default [
  {
    title: '探索线下任务场景，智蜂携百位博主打卡美博会。',
    desc: '从线上到线下，探索多任务场景闭环。',
    img: require('../assets/trends/img1.jpg'),
    html: `<article id="post-1367" class="post-1367 post type-post status-publish format-standard hentry category-17">
    <header class="entry-header">
        <h1 class="entry-title">智蜂参展第57届上海国际美博会，携百位博主现场打卡</h1>

        <div class="entry-meta">
            <span class="posted-on"
                >发表于2021年5月13日
        </div>
        <!-- .entry-meta -->
    </header>
    <!-- .entry-header -->

    <div class="entry-content">
        <div class="elementor elementor-1367">
            <div class="elementor-inner">
                <div class="elementor-section-wrap">
                    <section data-id="758bf319" class="elementor-element elementor-element-758bf319 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="607c84f4" class="elementor-element elementor-element-607c84f4 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="5bb751f2" class="elementor-element elementor-element-5bb751f2 elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-text-editor elementor-clearfix">
                                                        <p>2021年5月8日，第57届中国（上海）国际美博会暨2021上海大虹桥美博会在上海国家会展中心圆满落下了帷幕。本届博览会展览规模达23万平方米，4大主题展馆汇聚了两千多家品牌企业，囊括了日化、个护、彩妆、美容美体美发等不同行业近千个品类和上万款美业产品。</p>
                                                        <p>智蜂作为嘻呗新锐集市的合作机构（嘻呗是建立在美博会资源上衍生的美业大健康创业社交发生「声」平台），也参加了本次美博会。</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="5a33604" class="elementor-element elementor-element-5a33604 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="152aa15" class="elementor-element elementor-element-152aa15 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="b96c5f1" class="elementor-element elementor-element-b96c5f1 elementor-widget elementor-widget-image" data-element_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-image">
                                                        <figure class="wp-caption">
                                                            <img
                                                                src="/img/uploads/WechatIMG739.jpg"
                                                                class="attachment-full size-full"
                                                                alt=""
                                                                srcset="/img/uploads/WechatIMG739.jpg 4032w, /img/uploads/WechatIMG739-300x225.jpg 300w, /img/uploads/WechatIMG739-768x576.jpg 768w, /img/uploads/WechatIMG739-1024x768.jpg 1024w, /img/uploads/WechatIMG739-285x214.jpg 285w"
                                                                sizes="(max-width: 4032px) 100vw, 4032px"
                                                            />
                                                            <figcaption class="widget-image-caption wp-caption-text">智蜂展位</figcaption>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="6f8ff3e" class="elementor-element elementor-element-6f8ff3e elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="757acfa" class="elementor-element elementor-element-757acfa elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="162d0c1" class="elementor-element elementor-element-162d0c1 elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="da7d238" class="elementor-element elementor-element-da7d238 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="492f413" class="elementor-element elementor-element-492f413 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="ffaff4d" class="elementor-element elementor-element-ffaff4d elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-text-editor elementor-clearfix"><p>本次美博会上，智蜂充分发挥自身的技术、渠道和流量优势，不仅给现场的买家、商家和品牌主充分宣讲了智蜂独特的变现模式，也展示了智蜂强大的算法技术能力，同时也用成功的商家合作案例吸引了很多的观展人群。</p></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="e66d094" class="elementor-element elementor-element-e66d094 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="b886913" class="elementor-element elementor-element-b886913 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="0c3240a" class="elementor-element elementor-element-0c3240a elementor-widget elementor-widget-image" data-element_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-image">
                                                        <figure class="wp-caption">
                                                            <img
                                                            
                                                            
                                                                src="/img/uploads/商家.png"
                                                                class="attachment-full size-full"
                                                                alt=""
                                                                srcset="/img/uploads/商家.png 1080w, /img/uploads/商家-208x300.png 208w, /img/uploads/商家-768x1107.png 768w, /img/uploads/商家-710x1024.png 710w"
                                                                sizes="(max-width: 1080px) 100vw, 1080px"
                                                            />
                                                            <figcaption class="widget-image-caption wp-caption-text">商家在智蜂站台洽谈</figcaption>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="a297187" class="elementor-element elementor-element-a297187 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="dbdeece" class="elementor-element elementor-element-dbdeece elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="0262c4f" class="elementor-element elementor-element-0262c4f elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="dbb58ad" class="elementor-element elementor-element-dbb58ad elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="681db5d" class="elementor-element elementor-element-681db5d elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="1590d54" class="elementor-element elementor-element-1590d54 elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-text-editor elementor-clearfix"><p>现场的互动小礼品也是必不可少的，用户扫码即可获得智蜂定制手提袋一个，集潮流和实用为一体的手提袋一度成为展会现场的明星单品，广受好评。</p></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="38e3cd2" class="elementor-element elementor-element-38e3cd2 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="bd0daee" class="elementor-element elementor-element-bd0daee elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="dcc4ebf" class="elementor-element elementor-element-dcc4ebf elementor-widget elementor-widget-image" data-element_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-image">
                                                        <img
                                                        
                                                        
                                                            src="/img/uploads/WechatIMG747-768x1024.jpg"
                                                            class="attachment-large size-large"
                                                            alt=""
                                                            srcset="/img/uploads/WechatIMG747-768x1024.jpg 768w, /img/uploads/WechatIMG747-225x300.jpg 225w, /img/uploads/WechatIMG747.jpg 1080w"
                                                            sizes="(max-width: 640px) 100vw, 640px"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="dd9f83c" class="elementor-element elementor-element-dd9f83c elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="cb36e56" class="elementor-element elementor-element-cb36e56 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="fae013d" class="elementor-element elementor-element-fae013d elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="f44c041" class="elementor-element elementor-element-f44c041 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="1a804f3" class="elementor-element elementor-element-1a804f3 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="4020eb5" class="elementor-element elementor-element-4020eb5 elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-text-editor elementor-clearfix">
                                                        <p>当然，作为一个致力于帮助自媒体变现并且坐拥200000+自媒体博主的的平台，智蜂又怎么会放弃这么好的一个线下探店的机会呢！</p>
                                                        <p>这不，早在几周前智蜂就已经在智蜂app上发布了「魔都探店」的任务，目的地就是美博会上的「嘻呗新锐集市」。博主在智蜂app申请任务通过后不仅可以现场打卡美博会，还能免费拿品牌小样、玩游戏薅礼品、领盲盒，在完成任务后还可以获得智蜂提供的推广费奖励。让博主不仅能潮玩整个展区，还能在玩乐的同时赚得开心。</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="7c98e87" class="elementor-element elementor-element-7c98e87 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="042c6f6" class="elementor-element elementor-element-042c6f6 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="e8bde72" class="elementor-element elementor-element-e8bde72 elementor-widget elementor-widget-image" data-element_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-image">
                                                        <figure class="wp-caption">
                                                            <img src="/img/uploads/IMB_bOkyOA.gif" class="attachment-large size-large" alt="" />
                                                            <figcaption class="widget-image-caption wp-caption-text">智蜂博主签到打卡拍照区</figcaption>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="3e51a09" class="elementor-element elementor-element-3e51a09 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="6c7bda5" class="elementor-element elementor-element-6c7bda5 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="fa105d5" class="elementor-element elementor-element-fa105d5 elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="953d444" class="elementor-element elementor-element-953d444 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="346f270" class="elementor-element elementor-element-346f270 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="bec5403" class="elementor-element elementor-element-bec5403 elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-text-editor elementor-clearfix">
                                                        <p>接下来我们一起简单回顾一下博主如何现场打卡和做任务的吧！</p>
                                                        <p>智蜂展位为博主设置了签到墙、照片墙和拍照打卡区，同时还有精美定制手提袋相送。</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="45291b1" class="elementor-element elementor-element-45291b1 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="719884b" class="elementor-element elementor-element-719884b elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="da22f1d" class="elementor-element elementor-element-da22f1d elementor-widget elementor-widget-image" data-element_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-image">
                                                        <figure class="wp-caption">
                                                            <img src="/img/uploads/IMB_EyIy4R.gif" class="attachment-large size-large" alt="" />
                                                            <figcaption class="widget-image-caption wp-caption-text">博主到场</figcaption>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="b413b74" class="elementor-element elementor-element-b413b74 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="938171f" class="elementor-element elementor-element-938171f elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="a01d882" class="elementor-element elementor-element-a01d882 elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="63691b3" class="elementor-element elementor-element-63691b3 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="3ffbca5" class="elementor-element elementor-element-3ffbca5 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="1057810" class="elementor-element elementor-element-1057810 elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-text-editor elementor-clearfix"><p>博主到场后首先到智蜂展位领取一张「嘻呗破圈“颜”究所」的邀请函，拿着这张邀请函在嘻呗新锐集市集满5个章就可到破盒屋去领取盲盒伴手礼啦！</p></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="09fa49a" class="elementor-element elementor-element-09fa49a elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="c908ff8" class="elementor-element elementor-element-c908ff8 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="e7d213f" class="elementor-element elementor-element-e7d213f elementor-widget elementor-widget-image" data-element_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-image">
                                                        <figure class="wp-caption">
                                                            <img src="/img/uploads/IMB_HLLDcH.gif" class="attachment-large size-large" alt="" />
                                                            <figcaption class="widget-image-caption wp-caption-text">贴上这个臂贴，你就是整个展馆最靓的仔</figcaption>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="c02f5f5" class="elementor-element elementor-element-c02f5f5 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="28593ab" class="elementor-element elementor-element-28593ab elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="f73a623" class="elementor-element elementor-element-f73a623 elementor-widget elementor-widget-image" data-element_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-image">
                                                        <figure class="wp-caption">
                                                            <img src="/img/uploads/IMB_zQ6FFO.gif" class="attachment-large size-large" alt="" />
                                                            <figcaption class="widget-image-caption wp-caption-text">博主集章</figcaption>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="08c9525" class="elementor-element elementor-element-08c9525 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="c14e0a2" class="elementor-element elementor-element-c14e0a2 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="efa8fe4" class="elementor-element elementor-element-efa8fe4 elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="bfd0149" class="elementor-element elementor-element-bfd0149 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="be35dab" class="elementor-element elementor-element-be35dab elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="326a7e6" class="elementor-element elementor-element-326a7e6 elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-text-editor elementor-clearfix"><p>签完章后，博主可在智蜂签到墙上签名，留下属于博主自己的特别的印记。</p></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="134fcb7" class="elementor-element elementor-element-134fcb7 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="9809522" class="elementor-element elementor-element-9809522 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="a37f184" class="elementor-element elementor-element-a37f184 elementor-widget elementor-widget-image" data-element_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-image">
                                                        <img src="/img/uploads/签到.gif" class="attachment-large size-large" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="83d9d4b" class="elementor-element elementor-element-83d9d4b elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="3fdb96a" class="elementor-element elementor-element-3fdb96a elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="3a4b490" class="elementor-element elementor-element-3a4b490 elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="2ab94fb" class="elementor-element elementor-element-2ab94fb elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="19722ed" class="elementor-element elementor-element-19722ed elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="1011f24" class="elementor-element elementor-element-1011f24 elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-text-editor elementor-clearfix"><p>接下来博主到拍照区拍照。现场我们也为博主提供了各种不同的拍照道具，博主可选用自己喜欢的样式和标语进行拍照。</p></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="b6dd32c" class="elementor-element elementor-element-b6dd32c elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="c41d1df" class="elementor-element elementor-element-c41d1df elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="c63ba76" class="elementor-element elementor-element-c63ba76 elementor-widget elementor-widget-image" data-element_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-image">
                                                        <img src="/img/uploads/IMB_iM87xE.gif" class="attachment-large size-large" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="f03bc82" class="elementor-element elementor-element-f03bc82 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="49ca227" class="elementor-element elementor-element-49ca227 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="32f8711" class="elementor-element elementor-element-32f8711 elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="b814fc9" class="elementor-element elementor-element-b814fc9 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="6f9cdb4" class="elementor-element elementor-element-6f9cdb4 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="1f6a519" class="elementor-element elementor-element-1f6a519 elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-text-editor elementor-clearfix"><p>为博主精心准备的照片墙，美美哒！博主还可以和自己的照片来张合影哦～</p></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="3b1b687" class="elementor-element elementor-element-3b1b687 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="19f98b7" class="elementor-element elementor-element-19f98b7 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="a72f050" class="elementor-element elementor-element-a72f050 elementor-widget elementor-widget-image" data-element_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-image">
                                                        <img src="/img/uploads/IMB_Pq2uw7.gif" class="attachment-large size-large" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="d6612a3" class="elementor-element elementor-element-d6612a3 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="3478707" class="elementor-element elementor-element-3478707 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="1831c5f" class="elementor-element elementor-element-1831c5f elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="5b62526" class="elementor-element elementor-element-5b62526 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="71b4775" class="elementor-element elementor-element-71b4775 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="d2ba0df" class="elementor-element elementor-element-d2ba0df elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-text-editor elementor-clearfix"><p>当然，作为智蜂的合作博主，定制镭射包也是少不了的。到场的每一位博主均可领取一个，不仅好看，而且在观展的时候拿来装一些薅来的小礼物也是极好的～</p></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="883d934" class="elementor-element elementor-element-883d934 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="fb129ae" class="elementor-element elementor-element-fb129ae elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="a03157a" class="elementor-element elementor-element-a03157a elementor-widget elementor-widget-image" data-element_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-image">
                                                        <img src="/img/uploads/IMB_LAD0vf.gif" class="attachment-large size-large" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="a2515cb" class="elementor-element elementor-element-a2515cb elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="c4efa25" class="elementor-element elementor-element-c4efa25 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="8183d3c" class="elementor-element elementor-element-8183d3c elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="ac444da" class="elementor-element elementor-element-ac444da elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="b6477ca" class="elementor-element elementor-element-b6477ca elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="9548a28" class="elementor-element elementor-element-9548a28 elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-text-editor elementor-clearfix"><p>在智蜂拍照打卡之后，接下来博主就可以到其他展位去做任务和集章啦。任务主要有扭蛋机、幸运转盘、打卡拍照等。任务都不难，完成任务后可获得一定的小礼品。</p></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="1dbf07f" class="elementor-element elementor-element-1dbf07f elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="76cc9aa" class="elementor-element elementor-element-76cc9aa elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="7ae5c22" class="elementor-element elementor-element-7ae5c22 elementor-widget elementor-widget-image" data-element_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-image">
                                                        <figure class="wp-caption">
                                                            <img
                                                            
                                                            
                                                                src="/img/uploads/IMG_1224-767x1024.jpg"
                                                                class="attachment-large size-large"
                                                                alt=""
                                                                srcset="/img/uploads/IMG_1224-767x1024.jpg 767w, /img/uploads/IMG_1224-225x300.jpg 225w, /img/uploads/IMG_1224-768x1025.jpg 768w, /img/uploads/IMG_1224.jpg 1080w"
                                                                sizes="(max-width: 640px) 100vw, 640px"
                                                            />
                                                            <figcaption class="widget-image-caption wp-caption-text">图源见水印</figcaption>
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="ea49d67" class="elementor-element elementor-element-ea49d67 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="94fe6ec" class="elementor-element elementor-element-94fe6ec elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="d790f55" class="elementor-element elementor-element-d790f55 elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="90a057e" class="elementor-element elementor-element-90a057e elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="982f074" class="elementor-element elementor-element-982f074 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="f3f847f" class="elementor-element elementor-element-f3f847f elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-text-editor elementor-clearfix">
                                                        <p>集满五个章，即可到破盒屋领取由胖嘟嘟的“虎爪”送出的大礼包一份。每个礼包内的礼品都不同，至于能不能拿到自己心仪的，那就完全看各自的运气了哦～</p>
                                                        <p>拿到盲盒礼包后，现场打卡的任务就阶段性完成啦！</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="2e38559" class="elementor-element elementor-element-2e38559 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="10653ce" class="elementor-element elementor-element-10653ce elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="5af8a8c" class="elementor-element elementor-element-5af8a8c elementor-widget elementor-widget-image" data-element_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-image">
                                                        <img src="/img/uploads/盲盒.jpg" class="attachment-large size-large" alt="" srcset="/img/uploads/盲盒.jpg 887w, /img/uploads/盲盒-300x221.jpg 300w, /img/uploads/盲盒-768x566.jpg 768w" sizes="(max-width: 640px) 100vw, 640px" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="33ca6fb" class="elementor-element elementor-element-33ca6fb elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="e1bc31f" class="elementor-element elementor-element-e1bc31f elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="9555328" class="elementor-element elementor-element-9555328 elementor-widget elementor-widget-spacer" data-element_type="spacer.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-spacer">
                                                        <div class="elementor-spacer-inner"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="7062db0" class="elementor-element elementor-element-7062db0 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="8fdca29" class="elementor-element elementor-element-8fdca29 elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="76df2b1" class="elementor-element elementor-element-76df2b1 elementor-widget elementor-widget-text-editor" data-element_type="text-editor.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-text-editor elementor-clearfix">
                                                        <p>未来，智蜂将一如既往地服务于自媒体，为自媒体变现提供更便捷、更高效的方式和渠道；也将探索更多的任务玩法，让自媒体能在做任务变现的过程中体会到更多的乐趣和成就感；同时智蜂也会继续丰富更多的线上和线下互动体验场景，让博主与博主之间、博主与商家之间和博主与智蜂之间产生更加紧密的联系。</p>
                                                        <p>最后，来感受一波现场博主打卡的美图吧～</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section data-id="abdd114" class="elementor-element elementor-element-abdd114 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-section elementor-top-section" data-element_type="section">
                        <div class="elementor-container elementor-column-gap-default">
                            <div class="elementor-row">
                                <div data-id="7debccf" class="elementor-element elementor-element-7debccf elementor-column elementor-col-100 elementor-top-column" data-element_type="column">
                                    <div class="elementor-column-wrap elementor-element-populated">
                                        <div class="elementor-widget-wrap">
                                            <div data-id="1a13799" class="elementor-element elementor-element-1a13799 elementor-widget elementor-widget-image" data-element_type="image.default">
                                                <div class="elementor-widget-container">
                                                    <div class="elementor-image">
                                                        <img
                                                        
                                                        
                                                            src="/img/uploads/pt2021_05_12_17_58_06-200x1024.jpg"
                                                            class="attachment-large size-large"
                                                            alt=""
                                                            srcset="/img/uploads/pt2021_05_12_17_58_06-200x1024.jpg 200w, /img/uploads/pt2021_05_12_17_58_06-768x3931.jpg 768w, /img/uploads/pt2021_05_12_17_58_06.jpg 1000w"
                                                            sizes="(max-width: 200px) 100vw, 200px"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</article>`
  },
  {
    title: '筑巢科技2020团建之清远漂流记。',
    desc: '人在清远为“漂”客。',
    img: require('../assets/trends/img2.jpg'),
    html: `<article id="post-1335" class="post-1335 post type-post status-publish format-standard has-post-thumbnail hentry category-17">

	<header class="entry-header">

		<h1 class="entry-title">筑巢科技2020“Honey Party”团建之清远行</h1>

		<div class="entry-meta">

			<span class="posted-on">发表于2020年9月17日
		</span></span></div><!-- .entry-meta -->

	</header><!-- .entry-header -->

	<div class="entry-content">

		<p>2020年9月11至9月12日，筑巢科技的珠海和深圳团队小伙伴共同前往素有中国漂流之乡的广东清远，开展了为期两天的“honey party”团建活动。</p>
<p>珠海和深圳小伙伴兵分两路于11日早上8点各自搭乘大巴前往清远的午餐集合点，午餐过后即马不停蹄地赶往车程几分钟的团建第一站——户外拓展基地。</p>
<p><img class="alignnone size-full wp-image-1336" src="/img/uploads/WechatIMG529.jpg" alt="" srcset="/img/uploads/WechatIMG529.jpg 4032w, /img/uploads/WechatIMG529-300x225.jpg 300w, /img/uploads/WechatIMG529-768x576.jpg 768w, /img/uploads/WechatIMG529-1024x768.jpg 1024w, /img/uploads/WechatIMG529-285x214.jpg 285w" sizes="(max-width: 4032px) 100vw, 4032px"></p>
<p>&nbsp;</p>
<p>在拓展基地教练的组织下，团队小伙伴们首先进行了破冰活动，团队成员之间更加亲密、互相之间也更加了解了。</p>
<p>接下来是分组比赛，所有小伙伴随机分成四组进行团队比赛。通过紧张又激烈的团队比拼，小伙伴们领会到了团队协作的重要性。一个团队能走多快多远，是由最后那一名成员决定的，团队之间只有互相帮扶才能共同进步。</p>
<p><img class="alignnone size-full wp-image-1354" src="/img/uploads/lADPBG1Q5onPc8fNC9DND8A_4032_3024.jpg" alt="" srcset="/img/uploads/lADPBG1Q5onPc8fNC9DND8A_4032_3024.jpg 4032w, /img/uploads/lADPBG1Q5onPc8fNC9DND8A_4032_3024-300x225.jpg 300w, /img/uploads/lADPBG1Q5onPc8fNC9DND8A_4032_3024-768x576.jpg 768w, /img/uploads/lADPBG1Q5onPc8fNC9DND8A_4032_3024-1024x768.jpg 1024w, /img/uploads/lADPBG1Q5onPc8fNC9DND8A_4032_3024-285x214.jpg 285w" sizes="(max-width: 4032px) 100vw, 4032px"> <img class="alignnone size-full wp-image-1355" src="/img/uploads/WechatIMG168.jpg" alt="" srcset="/img/uploads/WechatIMG168.jpg 4032w, /img/uploads/WechatIMG168-300x225.jpg 300w, /img/uploads/WechatIMG168-768x576.jpg 768w, /img/uploads/WechatIMG168-1024x768.jpg 1024w, /img/uploads/WechatIMG168-285x214.jpg 285w" sizes="(max-width: 4032px) 100vw, 4032px"></p>
<p style="text-align: center;"><em>（烈日下的奋力拼搏）</em></p>
<p>&nbsp;</p>
<p>拓展活动结束后，乘车前往酒店办理入住。洗个温水澡，换身干净衣服，接下来就是美妙的honey party晚宴时间。</p>
<p><img class="alignnone size-full wp-image-1339" src="/img/uploads/WechatIMG535.jpg" alt="" srcset="/img/uploads/WechatIMG535.jpg 3189w, /img/uploads/WechatIMG535-300x194.jpg 300w, /img/uploads/WechatIMG535-768x497.jpg 768w, /img/uploads/WechatIMG535-1024x662.jpg 1024w" sizes="(max-width: 3189px) 100vw, 3189px"></p>
<p>&nbsp;</p>
<p>晚宴上，首先由公司各个领导分享了自身对团队和公司未来的展望，小伙伴们表示非常有道理并认真谈论起了桌上的美食……</p>
<p>晚宴的高潮在小蜜蜂生日会。</p>
<p>筑巢科技历来有为团队成员过集体生日的传统。一起切蛋糕，各自许愿，一起唱生日歌，好不热闹。也希望筑巢的小伙伴们永远年轻，永远十八，永远有人为你准备生日蛋糕。</p>
<p><img class="alignnone size-full wp-image-1357" src="/img/uploads/fsfe.jpg" alt="" srcset="/img/uploads/fsfe.jpg 4032w, /img/uploads/fsfe-300x225.jpg 300w, /img/uploads/fsfe-768x576.jpg 768w, /img/uploads/fsfe-1024x768.jpg 1024w, /img/uploads/fsfe-285x214.jpg 285w" sizes="(max-width: 4032px) 100vw, 4032px"></p>
<p>&nbsp;</p>
<p>晚宴过后，是自由活动时间。</p>
<p>唱K、健身、乒乓球、泡温泉、狼人杀应有尽有。在各种游戏中大家其乐融融，团队氛围更浓，人与人之间距离更近。</p>
<p>&nbsp;</p>
<p>第二天，早早吃完早餐，乘大巴一起开往团建的最后一站——清远古龙峡。管他早到与晚到，先来一张大合照；管他爬山累不累，横幅一定拉到位；管他太阳大不大，没有一人会落下；管他漂流敢不敢，装备一定要备满。</p>
<p><img class="alignnone size-full wp-image-1358" src="/img/uploads/11111.jpg" alt="" srcset="/img/uploads/11111.jpg 4032w, /img/uploads/11111-300x225.jpg 300w, /img/uploads/11111-768x576.jpg 768w, /img/uploads/11111-1024x768.jpg 1024w, /img/uploads/11111-285x214.jpg 285w" sizes="(max-width: 4032px) 100vw, 4032px"></p>
<p style="text-align: center;"><em>（<del>到此一游</del>大合照）</em></p>
<p>&nbsp;</p>
<p>在古龙峡首先是爬山，体验古龙峡6位一体的玻璃观光组合。</p>
<p>古人云：天将降大任于斯人也，必先苦其心志，劳其筋骨，让其体验玻璃吊桥。古人又云：不上玻璃栈道者不足以谈人生。</p>
<p>所以，接下来向我们走来的是勇士方阵：</p>
<p><img class="size-full wp-image-1347" src="/img/uploads/WechatIMG530.jpg" alt="" srcset="/img/uploads/WechatIMG530.jpg 3754w, /img/uploads/WechatIMG530-300x205.jpg 300w, /img/uploads/WechatIMG530-768x525.jpg 768w, /img/uploads/WechatIMG530-1024x699.jpg 1024w" sizes="(max-width: 3754px) 100vw, 3754px"></p>
<p style="text-align: center;"><img class="alignnone size-full wp-image-1345" src="/img/uploads/WechatIMG533.jpg" alt="" srcset="/img/uploads/WechatIMG533.jpg 990w, /img/uploads/WechatIMG533-250x300.jpg 250w, /img/uploads/WechatIMG533-768x920.jpg 768w, /img/uploads/WechatIMG533-855x1024.jpg 855w" sizes="(max-width: 990px) 100vw, 990px"></p>
<p>&nbsp;</p>
<p>体验完各种玻璃项目，就是漂流了。不到长城非好汉，到清远不漂流就不是一位合格的“漂”客。</p>
<p>漂流，就是一堂生动的解放天性课程，各种原始的嚎叫声响彻整个峡谷。拿着瓢和玩具水枪的人，就是这片峡谷的王，没有工具的人只是被扔在船舱里任人宰割的鱼肉。</p>
<p>漂流过程中，你会体验到什么才叫做真正的“友谊的小船说翻就翻”——不是说船会翻，而是友谊会翻。这时你需要六亲不认，需要无差别攻击，对别人仁慈就是对自己的残忍。</p>
<p>但是，打铁还需自身硬，打水仗还需自己会水性！每每这个时候，那些不会游泳的小伙伴灵魂深处总有一声呐喊：我拉着船舷上的绳，就无法攻击你；我放下绳，就无法保护自己。</p>
<p>……</p>
<p>如果可以，我觉得我能写出一本峡谷漂流启示录。幸好同行的小伙伴打断了我：“hold on！hold on……你这瞎想的时间拿来拍几张照，他不香吗？”</p>
<p>香，挺香！</p>
<p><img class="alignnone size-full wp-image-1356" src="/img/uploads/lADPBGY16UA0PsTNBaDNBDg_1080_1440.jpg" alt="" srcset="/img/uploads/lADPBGY16UA0PsTNBaDNBDg_1080_1440.jpg 1080w, /img/uploads/lADPBGY16UA0PsTNBaDNBDg_1080_1440-225x300.jpg 225w, /img/uploads/lADPBGY16UA0PsTNBaDNBDg_1080_1440-768x1024.jpg 768w" sizes="(max-width: 1080px) 100vw, 1080px"></p>
<p><img class="alignnone size-full wp-image-1348" src="/img/uploads/WechatIMG531.jpg" alt="" srcset="/img/uploads/WechatIMG531.jpg 1703w, /img/uploads/WechatIMG531-300x190.jpg 300w, /img/uploads/WechatIMG531-768x487.jpg 768w, /img/uploads/WechatIMG531-1024x649.jpg 1024w" sizes="(max-width: 1703px) 100vw, 1703px"></p>
<p style="text-align: center;"><em>（漂流）</em></p>
<p>漂完冲个澡换身衣服，即使身体有些酸痛但必须当作无事发生，这是一个合格的漂客必备的技能。</p>
<p>再吃个晚午餐，夕阳还未西下，回程的大巴已经等候多时了。</p>
<p>按流程，这个时候还得来一句：累并快乐着！</p>
<p>&nbsp;</p>
<p>出游常有而团建不常有。</p>
<p>团建不仅仅是一起出去吃喝玩乐，也是一次释放压力的机会，更是一次加强团队协作的契机。在团队游戏中互相了解、互相帮助，加深彼此感情，为后续的团队成员间的高效协同奠定基础。</p>
<p>相信通过此次honey party的大团建活动，筑巢科技的小伙伴们的团队面貌能更上一层楼，能更自信更阳光，对团队的未来也更加有信心！</p>
<p>#honey inside#</p>

	</div><!-- .entry-content -->
</article>`
  },
  {
    title: '智蜂APP推出深色模式',
    desc: '科技向左，人文向右，中间站着dark mode。',
    img: require('../assets/trends/img3.jpg'),
    html: `<article id="post-1285" class="post-1285 post type-post status-publish format-standard has-post-thumbnail hentry category-14 tag-ios tag-9 tag-15">

	<header class="entry-header">

		<h1 class="entry-title">智蜂APP深色模式：科技向左人文向右，中间站着Dark Mode</h1>

		<div class="entry-meta">

			<span class="posted-on">发表于2020年6月11日
		</span></span></div><!-- .entry-meta -->

	</header><!-- .entry-header -->

	<div class="entry-content">

		<p>随着2019年下半年IOS13的发布，「深色模式」逐渐成为了智能手机的标配，后来更是随着微信的适配问题引发了更多的热议。</p>
<p><img class="alignnone size-full wp-image-1286" src="/img/uploads/1000.jpg" alt="" srcset="/img/uploads/1000.jpg 1000w, /img/uploads/1000-300x166.jpg 300w, /img/uploads/1000-768x425.jpg 768w" sizes="(max-width: 1000px) 100vw, 1000px"></p>
<p style="text-align: center;">（图源于网络）</p>
<p>&nbsp;</p>
<p>据百度百科，深色模式（Dark Mode）是在通过大量的“人因研究”后，对文字前景与深色背景对比度以及文字和系统图标的颜色进行优化处理，确保人眼观看感受一致、舒适和易读的一种模式。</p>
<p>通俗点说，深色模式基于人类在不同环境下的不同身体和心理状态，对系统的文字、图标等的颜色进行优化处理，能让人感觉更加舒适。</p>
<p><img class="alignnone size-full wp-image-1287" src="/img/uploads/1561432627419045744.jpg" alt="" srcset="/img/uploads/1561432627419045744.jpg 1031w, /img/uploads/1561432627419045744-300x168.jpg 300w, /img/uploads/1561432627419045744-768x431.jpg 768w, /img/uploads/1561432627419045744-1024x574.jpg 1024w" sizes="(max-width: 1031px) 100vw, 1031px"></p>
<p style="text-align: center;">（图源于网络）</p>
<p>&nbsp;</p>
<p>因此，深色模式不仅仅是一种技术进步对视觉语言的优化，也体现了科技发展带来的人文关怀。这一次，在科技与人文的交叉路口，我们遇到了Dark Mode。</p>
<p>智蜂也紧跟科技潮流，在智蜂APP上推出了深色模式。现在，只要你是iOS13及以上版本且更新了最新的智蜂APP版本，即可跟随系统同步开启深色模式。</p>
<p><img class="alignnone size-full wp-image-1288" src="/img/uploads/dark-mode.png" alt="" srcset="/img/uploads/dark-mode.png 1970w, /img/uploads/dark-mode-300x152.png 300w, /img/uploads/dark-mode-768x390.png 768w, /img/uploads/dark-mode-1024x520.png 1024w" sizes="(max-width: 1970px) 100vw, 1970px"></p>
<p>&nbsp;</p>
<p>此次智蜂iOS版本的更新，严格按照苹果操作系统中对深色模式的设计文档要求而设计，保证了智蜂APP在iOS操作场景中具有较高的系统统一性，确保了人眼观看感受的一致性、舒适性和阅读的易读性。</p>
<p><img class="alignnone size-full wp-image-1289" src="/img/uploads/dark-mode2.png" alt="" srcset="/img/uploads/dark-mode2.png 2273w, /img/uploads/dark-mode2-300x132.png 300w, /img/uploads/dark-mode2-768x338.png 768w, /img/uploads/dark-mode2-1024x451.png 1024w" sizes="(max-width: 2273px) 100vw, 2273px"></p>
<p>&nbsp;</p>
<p>那么相较于浅色模式，深色模式在一些使用环境下有哪些比较明显的优势呢？</p>
<p><strong><b>首先是省电。</b></strong>现在很多中高端手机基本上都标配OLED屏幕，支持单个像素自发光。当显示深色图像时，屏幕可以通过降低该部分像素亮度来实现，耗电也随之减少；如果显示纯黑色图像，那么像素可以彻底不发光，完全不耗电。</p>
<p><strong><b>其次是护眼。</b></strong>我们都知道，瞳孔长时间聚焦在光亮的地方，容易引起眼干眼疲劳，而深色模式下屏幕高亮面积明显减少，能有效缓解这一问题。不过话说回来，最重要的护眼方式，是建议大家尽量少盯着手机～</p>
<p><strong><b>再次是能减少对身边人不必要的干扰。</b></strong>可能我们都有过这样的体验：晚上睡觉，身边的人看手机光线可能过亮，很容易干扰到自己入睡。还有就是在电影院类似的环境下，打开手机刺眼的光线能影响到其他人的观影体验。如果用深黑模式就能有效避免这样的干扰。</p>
<p><strong><b>最后是能照顾到特殊群体。</b></strong>一些视力低下的人群可能会更加喜欢深色模式，比如对飞蚊症患者来说，通常明亮背景下更容易看到那些四处乱飞的“小黑点”，暗色的屏幕则多少能够缓解这方面的困扰。</p>
<p><img class="alignnone size-full wp-image-1290" src="/img/uploads/1000-3.jpg" alt=" srcset="/img/uploads/1000-3.jpg 750w, /img/uploads/1000-3-300x168.jpg 300w" sizes="(max-width: 750px) 100vw, 750px"></p>
<p style="text-align: center;">（图源于网络）</p>
<p>&nbsp;</p>
<p>当然，除了基本背景、字体和图标等组件颜色变化，在未来的迭代中，智蜂一方面会紧跟操作系统要求，另一方面也会根据用户反馈不断优化使用体验、丰富使用功能。不仅帮助自媒体更快更轻松实现变现，还让自媒体在使用智蜂APP时更舒适更省心。</p>
<p>&nbsp;</p>

	</div><!-- .entry-content -->

</article>`
  },
  {
    title: '玉鼠临蜂，筑巢科技“honey party”年会',
    desc: '2020年1月20日晚，以“honey party”为主题的筑巢科技2020年会在珠海嘉远世纪举行。',
    img: require('../assets/trends/img4.jpg'),
    html: `<article id="post-1008" class="post-1008 post type-post status-publish format-standard has-post-thumbnail hentry category-17 tag-11 tag-9 tag-10 tag-12">

	<header class="entry-header">

		<h1 class="entry-title">玉鼠临蜂 筑巢科技“Honey party”年会</h1>

		<div class="entry-meta">

			<span class="posted-on">发表于2020年6月16日
		</span></span></div><!-- .entry-meta -->

	</header><!-- .entry-header -->

	<div class="entry-content">

		<p>新年的脚步悄然而至，眨眼间新的一年我们即将起航，2020年1月10日晚，终于迎来了筑巢科技翘首以盼的年会盛典，以“Honey party”为主题的2020年度晚会，在珠海市嘉远世纪隆重举行。筑巢科技董事长埼玉，首席执行官阿童木协同全体筑巢科技员工举杯共庆，愿经历了忙碌充实的2019，迎来更加辉煌的2020，愿成就筑巢科技更好的未来！一路走来，离不开全员的鼎力支持，感谢筑巢科技员工这一年来的创新努力，坚持奋斗！</p>
<p><img class="alignnone size-full wp-image-1009" src="/img/uploads/图片1.png" alt="" srcset="/img/uploads/图片1.png 1263w, /img/uploads/图片1-300x123.png 300w, /img/uploads/图片1-768x316.png 768w, /img/uploads/图片1-1024x421.png 1024w" sizes="(max-width: 1263px) 100vw, 1263px"></p>
<p>年会开场，展开了新年年会领导问答环节，不同于普通的新年致辞，在这种别开生面的互动下，员工热情地提出许多有趣的问题，领导也都给予了幽默又有深度的解答和讲话，阿童木所回答的“不怕”精神，言语间也鼓舞了筑巢人一路向前的信心，不惧艰难，才能在这洪波中勇往前行。并紧紧扣住筑巢科技“honey inside”的精神，提出团结责任荣耀，大家一起创造！要平等对待每一个员工，增强人才匹配与人才沉淀的愿景。</p>
<p><img class="alignnone size-full wp-image-1019" src="/img/uploads/图片1-1.png" alt="" srcset="/img/uploads/图片1-1.png 1269w, /img/uploads/图片1-1-300x225.png 300w, /img/uploads/图片1-1-768x576.png 768w, /img/uploads/图片1-1-1024x768.png 1024w, /img/uploads/图片1-1-285x214.png 285w" sizes="(max-width: 1269px) 100vw, 1269px"></p>
<p>领导们还对公司全体员工表示衷心的感谢，感谢全体同仁一年来的付出和努力。回顾2019年发展过程中遇到的挑战与磨练，经历了艰辛与坎坷。筑巢科技要居安思危、戒骄戒躁，要反省不足、总结经验，做好对于2020年发展规划的战略部署。在2020年新形式下，共存危机与机遇，相信筑巢科技能够以即刻精神为核心，朝着既定的目标奋力前行！</p>
<p>在振奋人心的问答互动结束后，精彩纷呈的晚会节目也在主持人的祝福中开始啦，多才多艺的筑巢科技员工在此刻尽情的散发着自己的光彩。表演精彩有趣，让人拍掌称好，全场气氛高涨，充满欢声笑语。</p>
<p><img class="alignnone size-full wp-image-1020" src="/img/uploads/图片2-1.png" alt="" srcset="/img/uploads/图片2-1.png 1266w, /img/uploads/图片2-1-300x200.png 300w, /img/uploads/图片2-1-768x512.png 768w, /img/uploads/图片2-1-1024x683.png 1024w, /img/uploads/图片2-1-750x500.png 750w" sizes="(max-width: 1266px) 100vw, 1266px"></p>
<p><img class="alignnone size-full wp-image-1012" src="/img/uploads/图片4.png" alt="" srcset="/img/uploads/图片4.png 1265w, /img/uploads/图片4-300x200.png 300w, /img/uploads/图片4-768x512.png 768w, /img/uploads/图片4-1024x682.png 1024w, /img/uploads/图片4-750x500.png 750w" sizes="(max-width: 1265px) 100vw, 1265px"></p>
<p>极具创意科技感的”向前冲”，热情洋溢的好汉歌，代表了筑巢科技一颗颗炽热的心，创新与热血，不正是筑巢的员工的样众志成城，一腔热血。还有野狼disco激情四射，小蜜蜂代表的魔术表演震撼全场，活泼自信的失恋战线联盟舞蹈，把现场气氛推向了一个又一个的高潮！最后由三句半表演拔得头筹，幽默的表演让全场推向高潮，赢得年会表演节目的大奖。</p>
<p><img class="alignnone size-full wp-image-1013" src="/img/uploads/图片5.png" alt="" srcset="/img/uploads/图片5.png 1264w, /img/uploads/图片5-300x200.png 300w, /img/uploads/图片5-768x512.png 768w, /img/uploads/图片5-1024x683.png 1024w, /img/uploads/图片5-750x500.png 750w" sizes="(max-width: 1264px) 100vw, 1264px"></p>
<p><img class="alignnone size-full wp-image-1014" src="/img/uploads/图片6.png" alt="" srcset="/img/uploads/图片6.png 1264w, /img/uploads/图片6-300x200.png 300w, /img/uploads/图片6-768x512.png 768w, /img/uploads/图片6-1024x683.png 1024w, /img/uploads/图片6-750x500.png 750w" sizes="(max-width: 1264px) 100vw, 1264px"></p>
<p>另外还有抢红包，团队游戏互动与抽大奖等精彩环节，节目纷呈，只为给带给全体员工最难忘的“Honey party”记忆，举杯交错共庆余年，风火岁月一同前行！这次年会既是对2019年的一个告别与总结，也是对于2020年的一种期盼与热诚。</p>
<p><img class="alignnone size-full wp-image-1021" src="/img/uploads/图片4-1.png" alt="" srcset="/img/uploads/图片4-1.png 1265w, /img/uploads/图片4-1-300x200.png 300w, /img/uploads/图片4-1-768x512.png 768w, /img/uploads/图片4-1-1024x682.png 1024w, /img/uploads/图片4-1-750x500.png 750w" sizes="(max-width: 1265px) 100vw, 1265px"></p>
<p>一朝风雨身后事，策马扬鞭向前看。正如阿童木所提：玉鼠筑巢智蜂舞，福猪挥春金媒开，玉鼠临蜂！今年，筑巢科技并肩作战&ensp;，团结至坚不可摧，只为迎接挑战！厚积薄发在今朝，在新的一年共续2019年的金猪承福，在2020金鼠之年，于互联网的风雨中无所畏惧，只求勿忘初心，开山破路，走出筑巢科技的一片金色光明！</p>
<p>精彩还在继续，感恩过去，展望未来，戒骄戒躁，稳步前行！未来筑巢科技定会携手并进，砥砺前行，只争朝夕，不负韶华。2019，再见，让筑巢科技再战2020！</p>

	</div><!-- .entry-content -->
</article>`
  },
  {
    title: '“Honey Party”团建，聚心筑巢',
    desc: '筑巢科技珠海与深圳的员工共赴惠州博罗，开展为期两天的“Honey Party”大团建活动',
    img: require('../assets/trends/img5.jpg'),
    html: `<article id="post-985" class="post-985 post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized tag-11 tag-9 tag-10 tag-12">

	<header class="entry-header">

		<h1 class="entry-title">“Honey Party”团建，聚心筑巢</h1>

		<div class="entry-meta">

			<span class="posted-on">发表于2019年11月21日
		</span></span></div><!-- .entry-meta -->

	</header><!-- .entry-header -->

	<div class="entry-content">

		<p>时光飞梭，2019年只剩下最后一个月，为提高团队的凝聚力和执行力，增进彼此之间的感情与配合度，让大家能以最好的状态去冲刺2019年的最后时光，2019年11月15日-16日，筑巢科技珠海与深圳的员工共赴惠州博罗，开展为期两天的“Honey Party”大团建活动。</p>
<p>员工们于15日下午到达温泉酒店，在聚餐前更是“神枪手”出马，员工们都热情高涨。</p>
<p><img src="/img/uploads/2.jpg" alt=""></p>
<p>在团建聚餐上，几位领导都发表了各自对于筑巢科技未来展望的发言，表现了对员工们的工作期待，激昂的发言鼓舞着筑巢科技的每一位员工，对明日的工作又多了一份方向与动力。<br>
团建聚餐开始前，通过游戏让员工随机分成四组合作团队，这让员工更好地热情打破隔阂，增强互动沟通。餐后有趣的互动游戏，让员工团队间充满了欢声笑语。</p>
<p><img src="/img/uploads/3.jpg" alt=""></p>
<p>之后员工自行前往温泉浸泡放松身心，更积极参与KTV，台球，桌游等活动,气氛其乐融融</p>
<p><img src="/img/uploads/4.jpg" alt=""</p>
<p><img src="/img/uploads/41.jpg" alt=""></p>
<p>16日早上，筑巢科技大部队前面惠州罗湖山进行登山活动，四队合作团队蓄势待发，开启登山竞赛活动。</p>
<p><img src="/img/uploads/51.jpg" alt=""></p>
<p>登山的历练，通过团队合作，沟通协作，让员工的工作压力得到释放，团队默契得到提升，山上的美景与感动，一起铭记在筑巢科技员工的心中。</p>
<p><img src="/img/uploads/52.jpg" alt=""></p>
<p><img src="/img/uploads/53.png" alt=""></p>
<p>这次“Honey Party”大团建活动，体现了筑巢科技团队的智慧与激情，团队精神带来的成功的自信和成就感，可爱又有趣的员工们发展着各自的个性与才能，发扬着Honey inside的团队理念，携手一起去创造筑巢科技更加美好的未来！</p>

	</div><!-- .entry-content -->

</article>`
  },
];